@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.captchaWrap {
    user-select: none;
    margin: 10px auto 5px;
    width: 160px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    font-family: 'Merriweather', serif;
}

.line {
    position: absolute;
    width: 200%;
    left: -20px;
    opacity: 0.5;
}

.captchaTitle {
    text-align: center;
    font-size: 0.95em;
}

.inputCaptchaWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 25px;
}

.inputCaptcha {
    padding: 4px;
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: 500;
    border: 1px solid var(--blue);
    width: 120px;
    text-align: center;
}

.errorCaptcha {
    position: absolute;
    font-weight: 300;
    width: max-content;
    top: calc(100% - 2px);
    left: calc(50% - 50px);
    font-size: 0.75rem;
    color: var(--orange);
    user-select: none;
}

.inputCaptcha:focus {
    background-color: white;
    border-color: var(--light-orange);
}

.inputCaptchaSuccess,
.inputCaptchaSuccess:focus {
    background-color: var(--success-button);
    border-color: var(--success-button-border);
}
