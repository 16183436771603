.categoryTitle {
    font-weight: 500;
    text-align: center;
    font-size: 1.4em;
    margin: 30px 0 20px 0;
}

.infoWrap {
    text-align: center;
    width: fit-content;
    padding: 10px;
    border-top: 1px solid var(--light-grey);
    border-bottom: 1px solid var(--light-grey);
    margin: 0 auto;
}

.infoString {
    display: none;
}

@media screen and (max-width: 700px) {
    .infoArr {
        display: none;
    }
    .infoString {
        display: block;
    }
}
