.messageWrap {
}

.message {
    text-align: center;
    width: fit-content;
    min-width: 420px;
    max-width: 420px;
    margin: 0 auto;
    font-size: 1.1em;
    line-height: 1.5em;
    position: relative;
}

.notFromRussia {
    color: var(--blue);
    text-decoration: underline;
    cursor: pointer;
}

.messageDetailed {
    display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--base-white);
    padding: 15px 10px 10px;
    box-shadow: 0 0 10px 2px var(--ex-light-grey);
}

.notFromRussia:hover {
    text-decoration: none;
}

.messageDetailed a {
    text-decoration: underline;
}

.messageDetailed a:hover {
    text-decoration: none;
    color: var(--orange);
}

.messageVisible {
    display: block;
}

.crossWrap {
    position: absolute;
    top: -27px;
    right: -5px;
    transform: scale(0.6, 0.6);
}

@media screen and (max-width: 991px) {
    .messageWrap {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 480px) {
    .message {
        width: 100%;
        min-width: auto;
    }
}
