.amountManagerWrap {
    display: flex;
    gap: 6px;
    align-items: center;
    width: fit-content;
}

.amountText {
    font-weight: 500;
}
