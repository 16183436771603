.spreadsWrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.spreadWrap {
    cursor: pointer;
}
.spreadWrap img {
    border: 1px solid var(--light-beige);
    width: 100%;
}

.spreadTitle {
    font-size: 0.85em;
}

.imageOnlyWrap {
    max-width: 100vw;
    max-height: calc(100vh - 70px);
    position: relative;
}

.imageOnlyWrap img {
    max-width: 100vw;
    max-height: calc(100vh - 70px);
}

.crossWrap {
    position: absolute;
    top: -33px;
    right: 0;
}

@media screen and (max-width: 640px) {
    .spreadWrap {
        width: 80%;
    }
}
