.darkWindow {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7777;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkWindow);
    opacity: 0;
    transition: 0.3s all ease;
}

.darkWindowVisible {
    opacity: 1;
}
