.quantityWrap {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    color: var(--base-white);
    background-color: var(--strong-orange);
    position: absolute;
    padding: 1px 0 0 1px;
    bottom: 50%;
    left: 100%;
    font-size: .6rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}