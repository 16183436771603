@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    --base: #111;
    --darkWindow: #000000dd;
    --base-bg: #fffff5;
    --base-white: #fffffc;
    --base-white-2-cart: #fffffa;
    --dark-blue: #005066;
    --blue: #008bb3;
    /*--blue: #00758c;*/
    --semi-blue: #2492a6;
    --bgv-blue: #008bb3;
    --ex-light-grey: #e5e2cf;
    --light-grey: #bfbfbf;
    --semi-grey: #999;
    --semi-dark-grey: #737373;
    --dark-grey: #444;
    --ex-light-beige: #f5f5e9;
    --light-beige: #e0e0d1;
    --light-beige2: #ebebdd;
    --semi-beige: #d4d4c6;
    --ex-light-yellow: #fafae1;
    --ex-light-yellow-border: #d9d9ad;
    --light-yellow: #f7f7d3;
    --ex-light-green: #f5fff2;
    --ex-light-green2: #e8f7e4;
    --ex-light-green3: #f1fced;
    --semi-green: #00af57;
    --green: #008040;
    --disabled: #f7f7f7;
    --light-orange: #ffd580;
    --semi-orange: #ffb042;
    --orange: #ee7700;
    --strong-orange: #e54d00;
    --submit-button: #ed9418;
    --success-button: #dcf2d5;
    --success-button-border: #98d998;
    --add-button: #e8faf0;
    --add-button-border: #add9c2;
    --warning: #f6d6ce;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    color: var(--base);
    background-color: var(--base-bg);
    font-size: 14px;
    line-height: 1.3em;
    -webkit-font-smoothing: antialiased;
}

textarea,
button,
input,
select {
    font-family: inherit;
    color: inherit;
    background-color: inherit;
    font-size: inherit;
    border: none;
    outline: none;
}

img {
    display: block;
}

.hidden {
    display: none;
}

.invisible {
    opacity: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

.fw300 {
    font-weight: 300;
}

.fw400 {
    font-weight: 400;
}

.fw500 {
    font-weight: 500;
}

.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;
}

.fz09em {
    font-size: 0.9em;
}

.center {
    text-align: center;
}

.u {
    text-decoration: underline;
}

.orange {
    color: var(--orange);
}

.strongOrange {
    color: var(--strong-orange);
}

.green {
    color: var(--green);
}

.container {
    padding: 0 0 120px;
    max-width: 1200px;
    min-height: calc(100vh - 135px);
    margin: 0 auto;
    position: relative;
}

.siteLink {
    position: absolute;
    top: 0;
    left: 0;
}

.itemName {
    font-weight: 500;
    font-size: 1.12em;
    line-height: 1.2em;
}

.itemYear {
    font-style: italic;
    font-size: 0.9em;
    color: var(--semi-grey);
    margin-left: 3px;
    margin-top: 1px;
}

.baseBlue {
    color: var(--blue);
}

.link {
    color: var(--blue);
    transition: all ease .15s;
}

.link:hover {
    color: var(--orange);
}

.noHyph {
    white-space: nowrap;
}

.youmoneyWord {
    color: #7f2bfd;
    font-weight: 600;
}

.semiGreenThankYou {
    color: var(--semi-green);
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 991px;
        padding: 0 30px 120px; /* depends on footer */
    }
}

@media screen and (max-width: 991px) {
    .container {
        padding: 0 15px 160px; /* depends on footer */
        min-height: calc(100vh - 100px); /* depends on header */
    }
}

@media screen and (max-width: 820px) {
    .container {
        padding-bottom: 170px; /* depends on footer */
    }
}

@media screen and (max-width: 640px) {
    .container {
        min-height: calc(100vh - 110px); /* depends on header */
    }
}
