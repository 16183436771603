.supportersWrap {
    width: fit-content;
    max-width: 300px;
    min-width: 250px;
    margin: 0 auto;
}

.subTitle {
    text-align: center;
    width: fit-content;
    padding: 5px 10px 25px;
    border-top: 1px solid var(--light-beige);
    margin: 0 auto;
    font-style: italic;
}

.supporterWrap {
    display: flex;
    min-height: 54px;
    justify-content: center;
    padding: 7px 10px;
    gap: 40px;
    border-top: 1px solid var(--light-beige);
}

.supporterWrap:last-child {
    border-bottom: 1px solid var(--light-beige);
}

.listNameAndRegionWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.listName {
    font-weight: 500;
    line-height: 1.2em;
}

.listRegion {
}

.listSum {
    font-weight: 500;
    white-space: nowrap;
}

.thankYou {
    margin: 30px auto 40px;
    text-align: center;
    font-weight: 500;
    color: var(--success-button-border);
    font-size: 1.2em;
}

/*@media screen and (max-width: 1200px) {*/
/*    .supportersWrap {*/
/*        width: 75%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 991px) {*/
/*    .supportersWrap {*/
/*        width: 85%;*/
/*    }*/
/*}*/

@media screen and (max-width: 360px) {
    .supportersWrap {
        width: 100%;
        min-width: 200px;
    }
}
