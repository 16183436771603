.allItemContentWrap {
    width: 70%;
    margin: 40px auto 0;
}

.itemWrap {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    position: relative;
}

.leftColumn {
    grid-column: 1/3;
    order: -1;
}

.rightColumn {
    grid-column: 3/6;
    position: relative;
}

.itemMsgWrap {
    position: absolute;
    top: -22px;
    right: calc(30% - 10px);
}

.smallImage {
    width: 70%;
}

.middleImage {
    width: 80%;
}

.largeImage {
    width: 100%;
}

.itemTitleWrap {
    margin-bottom: 15px;
}

.itemName {
    font-weight: 500;
    font-size: 1.3em;
    line-height: 1.3em;
}

.cdNumber {
    font-weight: 500;
    margin-bottom: 5px;
    margin-left: 5px;
}

.itemPrice {
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 1.15em;
}

.getEVersion {
    font-size: 0.8em;
    margin-left: 5px;
}

.subtitle {
    margin-bottom: 15px;
}

.trackList {
    list-style-position: inside;
    font-size: 1.05em;
    line-height: 1.4em;
}

.trackList li {
    white-space: pre-wrap;
    margin-bottom: 4px;
}

.realiseTime {
    margin-top: 12px;
    font-size: 1.05em;
    line-height: 1.4em;
    color: var(--dark-blue);
}

.descriptionList {
    list-style: none;
    grid-column: 1/4;
    margin-top: 30px;
}

.copyright {
    margin-top: 20px;
    grid-column: 1/3;
}

.backButton {
    font-size: 1.4em;
    font-weight: 300;
    grid-column: 1/3;
    margin: 30px 0 20px;
    opacity: 0.7;
    cursor: pointer;
}

.backButton:hover {
    opacity: 1;
}

@media screen and (max-width: 1200px) {
    .allItemContentWrap {
        width: 85%;
    }
}

@media screen and (max-width: 991px) {
    .allItemContentWrap {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .allItemContentWrap {
        width: 100%;
    }

    .getEVersion {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 640px) {
    .itemWrap {
        display: block;
    }

    .leftColumn {
        margin-top: 20px;
    }

    .smallImage,
    .middleImage,
    .largeImage {
        margin: 0 auto;
        display: block;
    }

    .itemMsgWrap {
        top: -12px;
        right: 15%;
    }
}
