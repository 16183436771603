.priceInputField {
    font-weight: 500;
    text-align: right;
    padding: 2px;
    border-bottom: 1px solid var(--light-grey);
    width: 60px;
}
::-webkit-input-placeholder {
    font-size: 0.8em;
    font-weight: 300;
    color: var(--semi-grey);
}
::-moz-placeholder {
    font-size: 0.8em;
    font-weight: 300;
    color: var(--semi-grey);
}
:-moz-placeholder {
    font-size: 0.8em;
    font-weight: 300;
    color: var(--semi-grey);
}
:-ms-input-placeholder {
    font-size: 0.8em;
    font-weight: 300;
    color: var(--semi-grey);
}
