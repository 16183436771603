.formWrap {
    position: relative;
    margin-bottom: 80px;
}

.form {
    margin: 0 auto 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.weNeedYourData {
    margin: 20px auto 10px;
    font-weight: 500;
    text-align: center;
    font-size: 1.1em;
}

.inputWrap {
    position: relative;
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 1.1em;
}

.error {
    position: absolute;
    font-weight: 300;
    top: calc(100% - 2px);
    left: calc(50% - 170px);
    font-size: 0.75rem;
    color: var(--orange);
    user-select: none;
}

.selectWrap {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    padding: 10px;
    font-size: 1.1em;
}

.selectLabel {
    font-weight: 500;
    margin-bottom: 10px;
}

.input,
.select {
    width: 340px;
    padding: 7px 10px;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--semi-dark-grey);
    font-size: 1.1em;
    font-weight: 500;
    transition: all 0.2s ease;
}

.select {
    width: 100%;
    font-weight: 400;
    font-size: 1.1em;
    padding: 5px;
}

.deliveryResult {
    font-size: 0.85em;
}

.input:focus,
.select:focus {
    border: 1px solid var(--light-orange);
    background-color: white;
}

.input::placeholder {
    color: var(--semi-grey);
    font-weight: 300;
    font-size: 0.9em;
}

.selectDefault {
    color: var(--semi-grey);
}

.selectNorm {
    color: var(--base);
}

.totalSum {
    text-align: center;
    background-color: var(--ex-light-green2);
    padding: 7px 15px;
    width: fit-content;
    margin: 20px auto 0;
    min-width: 250px;
}

.btn {
    background-color: var(--submit-button);
    border-radius: 3px;
    opacity: 0.85;
    font-size: 1.2em;
    padding: 7px 12px;
    color: var(--base-white);
    transition: 0.15s all ease;
    cursor: pointer;
}

.btn:hover {
    opacity: 1;
}

.btnDisabled {
    background-color: var(--disabled);
    border: 1px solid var(--light-beige);
    color: var(--light-grey);
    cursor: default;
}

.btnDisabled:hover {
    background-color: var(--disabled);
    border: 1px solid var(--light-beige);
}

.submittedItem {
    width: 340px;
    padding: 7px 10px;
    font-weight: 400;
    border-bottom: 1px solid var(--light-grey);
}

.checkboxWrap {
    display: flex;
    gap: 10px;
    margin: 10px 0;
}

.form__checkbox {
    opacity: 0;
    position: absolute;
}

.form__checkbox + label {
    display: block;
    user-select: none;
}

.form__checkbox + label::before {
    content: '';
    display: block;
    float: left;
    width: 14px;
    height: 14px;
    border: 1px solid var(--blue);
    border-radius: 3px;
    margin-right: 8px;
    top: 0;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 75% 75%;
    transition: 0.1s all linear;
    cursor: pointer;
}

.form__checkbox:checked + label::before {
    box-shadow: 0 0 3px 1px var(--semi-blue);
    border-color: var(--success-button-border);
    background-color: var(--success-button-border);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.form__checkboxLabel {
    font-size: 0.8rem;
    line-height: 1.5em;
    text-align: left;
}

.radioWrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 10px 0 20px;
}

.radioMainLabel {
    font-weight: 500;
    max-width: 570px;
}

.radioLabel {
    cursor: pointer;
}

.radioInput {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    appearance: none;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 1px solid var(--blue);
    margin-right: 5px;
    top: 1px;
    position: relative;
    transition: 0.1s all linear;
}

.radioInput:checked {
    border: 5px solid var(--blue);
    box-shadow: 0 0 0 3px #9ce3ee;
}

.renewCaptchaBtn {
    position: absolute;
    left: calc(50% + 100px);
    bottom: 125px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: var(--base);
    border: 1px solid var(--base);
    background-color: var(--base-white);
    padding: 3px;
    border-radius: 50%;
    opacity: 0.8;
    transition: all ease 0.15s;
}

.renewCaptchaBtn:hover {
    background-color: var(--light-beige2);
}

.btnRotated {
    transform: rotate(-180deg);
    transition: none;
}

@media screen and (max-width: 991px) {
    .formWrap {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 820px) {
    .formWrap {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 480px) {
    .input,
    .submittedItem {
        width: 80%;
    }
    .error {
        left: 10%;
    }
}
