.cartWrap {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--light-beige2);
    border-radius: 3px;
    background-color: var(--base-white);
    width: 70%;
}

.cartWrap img.gratisImage {
    width: 80px;
}

.warningPopWindow {
    width: calc(100vw - 30px);
    max-width: 700px;
    padding: 20px;
    position: relative;
    margin-top: 50px;
    font-size: 1.1em;
    line-height: 1.6em;
    background-color: var(--warning);
    border-radius: 3px;
}

.crossWrap {
    position: absolute;
    top: -35px;
    right: 0;
}

.itemWrap {
    border-bottom: 1px solid var(--light-beige2);
    padding: 15px;
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

/*.itemWrap:nth-child(even) {*/
/*    background-color: var(--base-white-2-cart);*/
/*}*/

.itemWrap:last-child {
    border-bottom: none;
}

.itemWrap img {
    width: 120px;
}

.itemWrapGratis {
    background-color: var(--ex-light-green);
}

.itemInfoWrap {
    display: grid;
    grid-template-columns: 3fr 5fr;
    align-items: end;
    gap: 40px;
    width: 100%;
}

.subtitle {
    margin-top: 5px;
}

.sumInfo {
    margin-top: 15px;
    font-size: 1.1em;
}

.fewOnes {
    display: inline;
}

.donationInputWrap {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
}

.itemManagerWrap {
    display: flex;
    gap: 20px;
}

.gratisAddPhrase {
    margin-bottom: 5px;
}

.thankYou {
    font-size: 1.2em;
    font-weight: 500;
    margin-top: 15px;
    color: var(--success-button-border);
    transform: scale(1, 0.95);
}

.inputError {
    font-size: 0.75em;
    color: var(--orange);
    height: 11px;
}

.itemsSumWrap {
    padding: 20px 0;
    width: 70%;
    margin: 0 auto 30px;
}

.itemsSum {
    font-size: 1.2em;
    display: flex;
    gap: 10%;
    justify-content: center;
    align-items: center;
}

.confirmButton {
    background-color: var(--submit-button);
    border-radius: 3px;
    opacity: 0.85;
    padding: 7px 12px;
    color: var(--base-white);
    transition: 0.15s all ease;
    cursor: pointer;
}

.confirmButton:hover {
    opacity: 1;
}

@media screen and (max-width: 1200px) {
    .cartWrap,
    .itemsSumWrap {
        width: 85%;
    }
}

@media screen and (max-width: 991px) {
    .cartWrap,
    .itemsSumWrap {
        width: 95%;
    }
    .itemInfoWrap {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}

@media screen and (max-width: 768px) {
    .cartWrap,
    .itemsSumWrap {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .cartWrap img.gratisImage {
        display: none;
    }
}

@media screen and (max-width: 640px) {
    .itemWrap {
        align-items: flex-start;
    }
    .itemInfoWrap {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }
    .itemsSum {
        justify-content: space-between;
        gap: 0;
    }
}

@media screen and (max-width: 480px) {
    .cartWrap {
        border-left-color: transparent;
        border-right-color: transparent;
    }
    .itemsSum {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }
    .itemWrap {
        padding: 15px 0;
    }
    .itemWrap img {
        width: 100px;
    }
}

@media screen and (max-width: 360px) {
    .fewOnes {
        display: block;
        width: fit-content;
        padding-bottom: 1px;
        border-bottom: 1px solid var(--ex-light-grey);
    }
    .separator {
        display: none;
    }
}
