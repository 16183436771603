.topBlockWrap {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 40px;
    background-color: var(--bgv-blue);
    color: white;
    font-weight: 500;
    font-size: 1.1em;
    position: relative;
    box-shadow: 0 0 15px 5px var(--light-grey);
}

.topInfoWrap {
    cursor: pointer;
    width: 100%;
    height: 100%;
    column-gap: 10px;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    opacity: 0.9;
    transition: 0.1s ease all;
}

.topInfoWrap:hover {
    opacity: 1;
}

.leftPart {
    display: flex;
    align-items: center;
    gap: 10px;
}

.topExplosionImg {
    height: 33px;
}

.rightPart {
    display: flex;
    align-items: center;
    gap: 10px;
}

.details {
    font-weight: 400;
    color: var(--disabled);
    text-decoration: underline;
    font-size: 0.9em;
}

.veryTopPopWindow {
    min-width: 300px;
    width: 800px;
    left: calc(50% - 400px);
    top: 100%;
    position: absolute;
    z-index: 777;
    color: var(--base);
    font-weight: 400;
    line-height: 1.4em;
    background-color: var(--base-white);
    padding: 20px;
    box-shadow: 0 0 10px 2px var(--ex-light-grey);
}

.crossWrap {
    position: absolute;
    top: 0;
    right: -5px;
    transform: scale(0.6, 0.6);
}

@media screen and (max-width: 991px) {
    .veryTopPopWindow {
        width: 640px;
        left: calc(50% - 320px);
    }
}

@media screen and (max-width: 640px) {
    .topBlockWrap {
        height: 50px;
        padding: 5px;
    }

    .topExplosionImg {
        display: none;
    }

    .veryTopPopWindow {
        width: 100%;
        left: 0;
    }
}
