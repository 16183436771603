.header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--base-bg);
    padding-bottom: 15px;
}

.siteLink {
    position: absolute;
    top: 50px;
    left: calc(50% - 470px);
    /*left: 50px;*/
    font-size: 1.1em;
    color: var(--semi-dark-grey);
    display: flex;
    gap: 7px;
}

.siteLink:hover {
    color: var(--orange);
}

.cartLinkWrap {
    position: absolute;
    top: 50px;
    right: calc(50% - 470px);
    /*right: 50px;*/
    font-size: 1.1em;
    color: var(--semi-dark-grey);
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.cartLinkWrap:hover {
    color: var(--orange);
}

.cartLink {
    display: flex;
    gap: 5px;
    color: var(--blue);
    position: relative;
    transition: 0.1s all ease;
}

.cartLink:hover {
    color: var(--orange);
}

.cartLinkActive {
    display: flex;
    gap: 5px;
    color: var(--orange);
    position: relative;
    font-weight: 500;
}

.supportListDarkScreen {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--darkWindow);
    width: 100vw;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
}

.darkScreenActive {
    display: flex;
}

.supportListWrap {
    height: calc(100vh - 70px);
    background-color: var(--base-bg);
    width: 500px;
    overflow: scroll;
    position: relative;
}

.crossWrap {
    position: fixed;
    top: 5px;
    right: calc(50% - 260px);
}

.rightMobileBlock {
    display: none;
    position: absolute;
    top: 50px;
    right: 15px;
    gap: 35px;
    align-items: center;
    justify-content: flex-end;
}

.cartIcon {
    transform: scale(1.8, 1.8);
    color: var(--blue);
    position: relative;
}

.cartIconActive {
    transform: scale(1.8, 1.8);
    color: var(--orange);
    position: relative;
}

.quantityWrap {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    color: var(--base-white);
    background-color: var(--strong-orange);
    position: absolute;
    padding: 1px 0 0 1px;
    bottom: 50%;
    left: 80%;
    font-size: .6rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(.6, .6);
}

@media screen and (max-width: 991px) {
    .header {
        padding-bottom: 10px;
    }

    .siteLink {
        left: 15px;
    }

    .cartLinkWrap {
        display: none;
    }

    .supportLink {
        position: relative;
    }

    .rightMobileBlock {
        display: flex;
    }

    .navbarWrap {
        display: none;
    }
}

@media screen and (max-width: 640px) {
    .rightMobileBlock {
        top: 60px;
    }

    .siteLink {
        display: none;
        /*top: 60px;*/
    }
}
