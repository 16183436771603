.undo {
    color: var(--dark-grey);
    border-radius: 50%;
    padding: 4px 5px 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s all ease;
}

.undo:hover {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .undo {
        transform: scale(1.1, 1.1);
    }
}
