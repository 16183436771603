.burgerWrap {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.burgerLine {
    height: 2px;
    width: 90%;
    background-color: var(--blue);
}

.inclinedLineOne {
    width: 2px;
    background-color: var(--blue);
    height: 100%;
    transform: rotate(-45deg);
}

.inclinedLineTwo {
    width: 2px;
    background-color: var(--blue);
    height: 100%;
    transform: rotate(45deg);
    margin-top: -30px;
}

.burgerMenu {
    position: absolute;
    top: calc(100% + 15px);
    right: -15px;
    padding: 30px 20px;
    background-color: var(--ex-light-yellow);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: max-content;
    font-size: 1.2em;
    gap: 25px;
    transition: 0.5s all ease;
    box-shadow: 0 0 15px 1px var(--semi-beige);
}

.hiddenMenu {
    right: -250px;
    opacity: 0;
}

.burgerLink {
    color: var(--blue);
    display: flex;
    gap: 10px;
}

.burgerLink:hover {
    color: var(--orange);
}

.siteLink {
    display: none;
}

@media screen and (max-width: 640px) {
    .siteLink {
        display: flex;
    }
}
