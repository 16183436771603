.arrowWrap {
    transition: 0.3s all ease;
}

.arrowWrapRotated {
    transform: rotate(-90deg);
}

.arrow {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
