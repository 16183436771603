.cardsWrap {
    display: flex;
    margin: 20px 0;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 2.66%;
}

.booksCardsWrap {
    flex-direction: column;
}

@media screen and (max-width: 991px) {
    .cardsWrap {
        justify-content: center;
        column-gap: 6%;
    }
}
