.crossWrap {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.inclinedLineOne,
.inclinedLineTwo {
    margin: 0 auto;
    width: 2px;
    height: 100%;
    background-color: var(--semi-dark-grey);
    transform: rotate(-45deg);
}

.inclinedLineTwo {
    transform: rotate(45deg);
    margin-top: -30px;
}
