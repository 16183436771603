/*.gratisWrapTemp {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 20px;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin-top: 100px;*/
/*    font-size: 1.1em;*/
/*}*/

.gratisWrap {
    margin: 135px auto 0;
    width: 100%;
    max-width: 800px;
    background-color: var(--ex-light-green);
    border: 1px solid var(--semi-orange);
    border-radius: 3px;
    padding: 15px 10px;
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    justify-content: center;
    grid-template-rows: auto auto;
    /*gap: 15px;*/
}

.gratisWrap img {
    width: 80px;
    grid-row: 1/3;
}

.gratisTitleWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.gratisTitle {
    font-size: 1.05em;
    font-weight: 500;
    text-align: center;
}

.donationInputWrap {
    position: relative;
}

.inputError {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 0.8em;
    color: var(--orange);
    height: 10px;
    width: max-content;
}

.addButton {
    min-height: 30px;
    min-width: 155px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--ex-light-yellow);
    border: 1px solid var(--ex-light-yellow-border);
    padding: 0 10px;
    font-size: 0.9em;
    font-weight: 500;
    user-select: none;
    transition: 0.15s all ease;
}

.addButton:hover {
    background-color: var(--base-white);
    border: 1px solid var(--semi-orange);
}

.successButton {
    background-color: var(--success-button);
    border: 1px solid var(--success-button-border);
    cursor: default;
}

.successButton:hover {
    background-color: var(--success-button);
    border: 1px solid var(--success-button-border);
}

.inactiveButton {
    background-color: var(--disabled);
    border: 1px solid var(--light-beige);
    cursor: default;
}

.inactiveButton:hover {
    background-color: var(--disabled);
    border: 1px solid var(--light-beige);
}

.donationsWrap {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 20px;
}

.donationsInfo {
    color: var(--green);
    position: relative;
}

.donationsSum {
    font-weight: 500;
}

.aboutDonation {
    position: absolute;
    width: 320px;
    height: 150px;
    top: 115%;
    left: -10px;
    z-index: 7777;
    color: var(--base);
    font-weight: 400;
    line-height: 1.4em;
    background-color: var(--base-white);
    padding: 30px 20px;
    box-shadow: 0 0 10px 2px var(--ex-light-grey);
    font-size: 1.1em;
    opacity: 0;
    transition: 0.5s all ease;
}

.aboutDonationVisible {
    opacity: 1;
}

.crossWrap {
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(0.6, 0.6);
}

@media screen and (max-width: 991px) {
    .gratisWrap {
        margin-top: 100px;
    }
    .donationsInfo {
        display: none;
    }
}

@media screen and (max-width: 640px) {
    .gratisWrap {
        /*row-gap: 10px;*/
        /*column-gap: 0;*/
        margin-top: 110px;
    }
    .gratisWrap img {
        grid-row: 1/2;
        width: 60px;
    }
    .donationsWrap {
        grid-column: 1/3;
    }
}
