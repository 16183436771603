.supportWrap {
    margin: 135px auto 0;
    width: 100%;
    max-width: 550px;
}

.supportProlog {
    margin-top: 20px;
    text-align: center;
    font-size: 15px;
    line-height: 1.3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 991px) {
    .supportWrap {
        margin-top: 100px;
    }
}

@media screen and (max-width: 640px) {
    .supportWrap {
        margin-top: 110px;
    }
}