.talesWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.taleTitle {
    cursor: pointer;
    text-decoration: underline;
    color: var(--blue);
    font-size: 1.05em;
    opacity: 1;
    width: fit-content;
}

.taleTitle:hover {
    opacity: 0.8;
    text-decoration: none;
}

.taleWrap {
    white-space: pre-wrap;
    margin: 30px auto 20px;
    max-width: 800px;
    font-size: 1.2em;
    line-height: 1.4em;
}

.taleName {
    font-weight: 600;
    margin-bottom: 10px;
}

.backButton {
    font-size: 1.4em;
    font-weight: 300;
    margin: 0 0 20px;
    opacity: 0.7;
    cursor: pointer;
}

.backButton:hover {
    opacity: 1;
}

/*  for talesHOC  */
.taleTextWrap {
    max-width: 800px;
    max-height: calc(100vh - 50px);
    position: relative;
    width: 100%;
    font-size: 1.2em;
    line-height: 1.4em;
    margin-top: 50px;
    background-color: var(--base-white);
}

.taleText {
    white-space: pre-wrap;
    max-height: calc(100vh - 50px);
    overflow: scroll;
    padding: 20px;
    height: 100%;
}

.crossWrap {
    position: absolute;
    top: -33px;
    right: 0;
}
/*  end of for talesHOC  */
