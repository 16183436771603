.managerButton {
    cursor: pointer;
    font-size: 1.4em;
    border-radius: 3px;
    background-color: white;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--light-grey);
    transition: all 0.15s ease;
    user-select: none;
}

.managerButton:hover {
    background-color: var(--ex-light-beige);
}

.managerButton:active {
    background-color: var(--light-grey);
}

.managerButtonDisabled {
    background-color: var(--disabled);
    border: 1px solid var(--light-beige);
    cursor: default;
}

.managerButtonDisabled:hover {
    background-color: var(--disabled);
    cursor: default;
}

@media screen and (max-width: 768px) {
    .managerButton {
        width: 32px;
        height: 32px;
    }
}
