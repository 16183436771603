.donationFormWrap {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 auto;
    width: fit-content;
    position: relative;
}

.donationInputWrap {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-size: 1.14em;
    position: relative;
}

.priceFieldBack {
    order: 1;
}

.donationButton {
    height: 27px;
    border-radius: 3px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--ex-light-yellow);
    border: 1px solid var(--ex-light-yellow-border);
    font-size: 0.9em;
    font-weight: 500;
    user-select: none;
    transition: 0.15s all ease;
}

.donationButton:hover {
    background-color: var(--base-white);
    border: 1px solid var(--semi-orange);
}

.inputError {
    position: absolute;
    width: max-content;
    font-size: 0.7em;
    color: var(--orange);
    height: 8px;
    left: 0;
    top: 102%;
}

.successButton {
    background-color: var(--success-button);
    border: 1px solid var(--success-button-border);
    cursor: default;
}

.successButton:hover {
    background-color: var(--success-button);
    border: 1px solid var(--success-button-border);
}

.inactiveButton {
    border: 1px solid var(--light-beige);
    background-color: var(--disabled);
    cursor: default;
}

.inactiveButton:hover {
    background-color: var(--disabled);
    border: 1px solid var(--light-beige);
}

@media screen and (max-width: 768px) {
    .donationButton {
        height: 32px;
        width: 155px;
        font-size: 1.03em;
    }
}
